import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Avatar/Avatar.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Box/Box.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Container/Container.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Grid/Grid.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Stack/Stack.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/@mui/material/Typography/Typography.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["StyledCard","StyledImage","StyledTitleBox","StyledDescBox"] */ "/app/src/components/blog-card/style.ts");
;
import(/* webpackMode: "eager" */ "/app/src/components/typography.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/page/home/sections/style.ts");
